export default {
  title: "Административная панель | Radiogram",
  profile: "Профиль",
  settings: "Настройки",
  "settings.photo": "Фотография",
  "settings.changePassword": "Смена пароля",
  "settings.changePassword.newPassword": "Новый пароль",
  "settings.changePassword.repeatPassword": "Повторите ввод",
  "settings.changePassword.submit": "Изменить",
  "settings.changePassword.success": "Пароль успешно изменен",
  "settings.changePassword.error": "Ошибка изменения пароля",
  "settings.photo.upload": "Загрузить",

  Dashboard: "Отчеты",
  "Dashboard.date": "Дата",
  "Dashboard.value": "Значение",

  "Dashboard.scale": "Период",
  "Dashboard.scale.week": "Неделя",
  "Dashboard.scale.month": "Месяц",
  "Dashboard.scale.year": "Год",

  "Dashboard.Categories.Summary": "Сводка по категориям",
  "Dashboard.Categories.Dynamic": "Динамика по категориям",
  "Dashboard.Categories.MessagesStats": "Количество прослушиваний сообщений",
  "Dashboard.Categories.MessagesDynamic": "Динамика прослушиваний сообщений",
  "Dashboard.Categories.AdvertsStats": "Количество прослушиваний рекламы",
  "Dashboard.Categories.AdvertsDynamic": "Динамика прослушиваний рекламы",
  "Dashboard.Categories.CharsSummary": "Сводка по символам",
  "Dashboard.Categories.CharsDynamic": "Динамика по символам",

  "Dashboard.Messages.Summary": "Сводка по прослушиваниям",
  "Dashboard.Messages.Dynamic": "Динамика прослушиваний",

  "Dashboard.Activity.Retention": "RR",

  "Dashboard.Adverts.Summary": "Сводка по объявлениям",
  "Dashboard.Adverts.Dynamic": "Динамика объявлений",

  "Dashboard.Chars.Summary": "Сводка преобразований",
  "Dashboard.Chars.Dynamic": "Динамика преобразований",

  "Dashboard.ItemsCategories": "Категории",
  "Dashboard.Items.UsersSummary": "Владельцы",
  "Dashboard.Users.Dynamic": "Регистрации",
  "Dashboard.ItemsCategories.Dynamic": "Динамика активаций",
  "Dashboard.Partners.DynamicGender": "Регистрации по полу",
  "Dashboard.Partners.DynamicZodiac": "Регистрации по знакам",
  "Dashboard.Partners.SummaryGender": "Пол",
  "Dashboard.Partners.SummaryAge": "Возраст",
  "Dashboard.Partners.ScoresSummary": "Оценки",
  "Dashboard.Partners.SummaryZodiac": "Пользователи по знакам",

  "Dashboard.Products": "Cклад",
  "Dashboard.Payments.Dynamic": "Динамика платежей",
  "Dashboard.Payments.Summary": "Сводка платежей",
  "Dashboard.Payments.CurrencySummary": "Доходы {currencyName}",
  "Dashboard.Payments.CurrencySummary.ItemsCategories": "Цифровые игрушки",
  "Dashboard.Orders": "Заказы",
  "Dashboard.Orders.Count": "Количество заказов",
  "Dashboard.Orders.Total": "Деньги с заказов",
  "Dashboard.Payouts": "Выплаты",
  "Dashboard.Products.Dynamic": "Динамика склада",

  "Dashboard.KPI": "KPI",
  "Dashboard.KPI.newCount": "Новые",
  "Dashboard.KPI.notNewCount": "Повторные",

  "Dashboard.Payments": "Деньги",
  "Dashboard.Payments.payouts": "Фонды",
  "Dashboard.Payments.commission": "Комиссия",
  "Dashboard.Payments.waitForPayouts": "Не распределены",

  CastsTranslations: "Трансляции выпусков",
  "CastsTranslations.Info": "Информация",
  "CastsTranslations.Form": "Редактировать",
  "CastsTranslations.name": "Название",
  "CastsTranslations.time": "Время трансляции",
  "CastsTranslations.rtmps": "Публикация",
  "CastsTranslations.serverUrl": "Сервер трансляции",
  "CastsTranslations.translationKey": "Ключ трансляции",
  "CastsTranslations.videoFileId": "Видео-файл",

  // перевода для интерфейсов управления учетными записями
  Accounts: "Учетные записи",
  OpenAI: "Редакция",
  "OpenAI.Completions": "Обработчики",
  "OpenAI.Completions.Info": "Информация",
  "OpenAI.Completions.Form": "Редактировать",
  "OpenAI.Completions.name": "Название",
  "OpenAI.Completions.attrs": "Извлекаемые атрибуты",
  "OpenAI.Completions.type": "Тип обработчика",
  "OpenAI.Completions.type.scoring": "Оценка сообщения",
  "OpenAI.Completions.type.convert": "Конвертация",
  "OpenAI.Completions.instructions": "Инструкции",
  "OpenAI.Completions.categories": "Категории",

  "OpenAI.Assistants": "Ассистенты",
  "OpenAI.Assistants.Info": "Информация",
  "OpenAI.Assistants.Form": "Редактировать",
  "OpenAI.Assistants.name": "Название",
  // "OpenAI.Assistants.autoRun": "Автозапуск",
  // "OpenAI.Assistants.TimerRuns": "Запуск по таймеру",
  // "OpenAI.Assistants.TimerRuns.categories": "Категории",
  // "OpenAI.Assistants.TimerRuns.runtime": "Время запуска",
  // "OpenAI.Assistants.Criterias": "Критерии",
  // "OpenAI.Assistants.Criterias.attrName": "Атрибут",
  // "OpenAI.Assistants.Criterias.valueBoolean": "Да/нет",
  // "OpenAI.Assistants.approveCriterias": "Критерии одобрения",
  // "OpenAI.Assistants.rejectCriterias": "Критерии отказа",
  // "OpenAI.Assistants.PostProcessing": "Пост-обработка",
  // "OpenAI.Assistants.PostProcessing.schemaName": "Схема",
  // "OpenAI.Assistants.PostProcessing.schemaMethod": "Метод",
  // "OpenAI.Assistants.ModifyAttrs": "Модификаторы",
  // "OpenAI.Assistants.ModifyAttrs.attrName": "Атрибут",
  // "OpenAI.Assistants.ModifyAttrs.schemaName": "Схема",
  // "OpenAI.Assistants.ModifyAttrs.schemaMethod": "Метод",

  "OpenAI.Assistants.instructions": "Инструкции",
  "OpenAI.Assistants.openAIId": "OpenAI ID",
  "OpenAI.Assistants.saveDate": "Дата сохранения",
  "OpenAI.Assistants.syncDate": "Дата синхронизации",
  "OpenAI.Assistants.batchSize": "Размер пакета обработки",
  "OpenAI.Assistants.temperature": "Температура",
  "OpenAI.Assistants.top_p": "Top P",
  "OpenAI.Assistants.Sync": "Синхронизировать",

  "OpenAI.Casts": "Выпуски",
  "OpenAI.Casts.compile": "Компиляция",
  "OpenAI.Casts.title": "Заголовок",
  "OpenAI.Casts.category": "Категория",
  "OpenAI.Casts.channel": "Канал",
  "OpenAI.Casts.status": "Статус",
  "OpenAI.Casts.status.new": "Новый",
  "OpenAI.Casts.status.new.style": "primary",
  "OpenAI.Casts.status.progress": "В обработке",
  "OpenAI.Casts.status.progress.style": "info",
  "OpenAI.Casts.status.completed": "Модерация",
  "OpenAI.Casts.status.completed.style": "danger",
  "OpenAI.Casts.status.published": "Опубликован",
  "OpenAI.Casts.status.published.style": "success",
  "OpenAI.Casts.status.rejected": "Отклонен",
  "OpenAI.Casts.status.rejected.style": "danger",
  "OpenAI.Casts.status.error": "Ошибка",
  "OpenAI.Casts.status.error.style": "danger",
  "OpenAI.Casts.isLast": "Последний",
  "OpenAI.Casts.approve": "Опубликовать",
  "OpenAI.Casts.completedAt": "Завершено",
  "OpenAI.Casts.publishedAt": "Опубликовано",
  "OpenAI.Casts.reject": "Отклонить",
  "OpenAI.Casts.cancel": "Отменить",
  "OpenAI.Casts.restore": "Восстановить",
  "OpenAI.Casts.threadLogs": "Логи генерации",
  "OpenAI.Casts.restartsCount": "Количество перезапусков",
  "OpenAI.Casts.refreshIntro": "Обновить трейлер",
  "OpenAI.Casts.refreshCover": "Обновить картинку",
  "OpenAI.Casts.refreshCoverPrompt": "Введите prompt для генерации",

  "OpenAI.Schedules": "Расписание",
  "OpenAI.Schedules.assistant": "Ассистент",
  "OpenAI.Schedules.forCategories": "Для категорий",
  "OpenAI.CastsConcat": "Объединение выпусков",
  "OpenAI.Schedules.channels": "Каналы",
  "OpenAI.Schedules.channel": "Канал",
  "OpenAI.Schedules.category": "Категория",

  "OpenAI.Schedules.runtime": "Время запуска",
  "OpenAI.Schedules.runtime.time": "Время",
  "OpenAI.Schedules.runtime.timeTitle": "Подпись времени",
  "OpenAI.Schedules.runtime.periodTitle": "Периодичность",
  "OpenAI.Schedules.runtime.castTitle": "Подпись заголовка",
  "OpenAI.Schedules.runtime.title": "Заголовок",
  "OpenAI.Schedules.runtime.fileId": "Mp3",
  "OpenAI.Schedules.runtime.includePrevious": "Включая предыдущий выпуск",
  "OpenAI.Schedules.runtime.templateFileId": "Шаблон обложки",

  "OpenAI.Schedules.runtime.telegramTextPublish": "Формат публикации в телеграме",
  "OpenAI.Schedules.runtime.telegramTextPublish.audio": "Аудио сообщения",
  "OpenAI.Schedules.runtime.telegramTextPublish.text": "Текст с картинкой",

  "OpenAI.Schedules.runtime.telegramMakeStory": "Делать сториз?",
  "OpenAI.Schedules.runtime.templateStoryFileId": "Шаблон для сториз",
  "OpenAI.Schedules.runtime.templatePostFileId": "Шаблон для поста",

  "OpenAI.Schedules.runtime.telegramPublish": "Публикация выпуска в телеграме",
  "OpenAI.Schedules.runtime.telegramCollapse": "Публикация всех выпусков в одном сообщении",

  "OpenAI.Schedules.name": "Название",
  "OpenAI.Schedules.dailySum": "Данные за день",
  "OpenAI.Schedules.currentDayMessagesOnly": "Сообщения только за сегодня",
  "OpenAI.Schedules.everyDay": "Каждый день?",
  "OpenAI.Schedules.defaultTitle": "Заголовок по умолчанию",
  "OpenAI.Schedules.specificWeekDays": "По дням недели",
  "OpenAI.Schedules.specificMonthDays": "По дням месяца",
  "OpenAI.Schedules.categories": "Категории",
  "OpenAI.Schedules.imageGeneration": "Генерировать обложку?",
  "OpenAI.Schedules.imageGenerationInstructions": "Инструкции для генерации обложки",
  "OpenAI.Schedules.restartsLimit": "Количество допустимых перезапусков",
  "OpenAI.Schedules.minScore": "Минимальная оценка сообщений",
  "OpenAI.Schedules.publicationsMinCount": "Минимальное количество сообщений в выпуске",
  "OpenAI.Schedules.autoPublish": "Автоматическая публикация выпуска",
  "OpenAI.Schedules.autoPublish.short": "Автопубликация",

  "OpenAI.Schedules.Form": "Редактировать",
  "OpenAI.Schedules.Info": "Информация",
  "OpenAI.Schedules.Add": "Добавить расписание",
  "OpenAI.Schedules.Generations": "Генерации",
  "OpenAI.Schedules.Add": "Добавить расписание",

  "OpenAI.Threads": "Треды",
  "OpenAI.Threads.openAIId": "OpenAI ID",
  "OpenAI.Threads.category": "Категория",
  "OpenAI.Threads.lastMessageDate": "Последнее сообщение",
  "OpenAI.Threads.lastRunDate": "Последняя обработка",
  "OpenAI.Runs": "Обработки",
  "OpenAI.Runs.openAIId": "OpenAI ID",
  "OpenAI.Runs.startDate": "Дата запуска",
  "OpenAI.Runs.finishDate": "Дата окончания",

  telegramPublishChannel: "Публиковать в канал",

  telegramPublishFormat: "Формат публикации в телеграме",
  "telegramPublishFormat.text": "Текст с картинкой",
  "telegramPublishFormat.audio": "Аудио",
  "telegramPublishFormat.video": "Видео",
  "telegramPublishFormat.trailer": "Трейлер",

  // перевода для интерфейсов управления пользователями
  Users: "Пользователи",
  "Users.Add": "Добавить пользователя",
  "Users.Info": "Информация",
  "Users.Data": "Данные",
  "Users.User": "Данные пользователя",
  "Users.User.Save": "Редактировать пользователя",
  "Users.Form.changePhone": "Изменить номер телефона",
  "Users.isAdmin": "Админ",
  "Users.itemsCount": "Покупки",
  "Users.login": "Логин",
  "Users.name": "Имя",
  "Users.email": "Email",
  "Users.phone": "Номер телефона",
  "Users.password": "Пароль",
  "Users.access": "Права доступа",
  "Users.add": "Добавить",
  "Users.login-busy": "Логин занят",
  "Users.table.login": "Логин",
  "Users.table.name": "Имя",

  Logins: "Данные для авторизации",
  "Logins.Add": "Добавить логин",
  "Logins.Edit": "Изменить логин",
  "Logins.value": "Логин",
  "Logins.type": "Тип",
  "Logins.type.plain": "Обычный",
  "Logins.type.phone": "Телефон",
  "Logins.type.email": "Email",

  Accesses: "Права доступа",
  "Accesses.Add": "Добавить право доступа",
  "Accesses.Form": "Редактировать права доступа",
  "Accesses.title": "Название",
  "Accesses.components": "Компоненты",

  Catalogs: "Настройки",

  Telegram: "Телеграм",
  "Telegram.Accounts": "Учетные записи",
  "Telegram.Accounts.isOnline": "Онлайн",
  "Telegram.Accounts.isBot": "Это бот?",
  "Telegram.Accounts.token": "Токен",
  "Telegram.Accounts.apiId": "API Id",
  "Telegram.Accounts.apiHash": "API Hash",

  Partners: "Админы",
  "Partners.Add": "Добавить админ",
  "Partners.Form": "Редактировать данные админа",
  "Partners.Info": "Информация",
  "Partners.Users": "Аккаунты",
  "Partners.Users.account": "Аккаунт",
  "Partners.Users.access": "Права доступа",
  "Partners.Data": "Данные админа",
  "Partners.name": "Название",
  "Partners.channelsCount": "Кол-во каналов",
  "Partners.ident": "ID",

  Replacements: "Замены",
  "Replacements.Add": "Добавить замену",
  "Replacements.Form": "Редактировать замену",
  "Replacements.Info": "Информация",
  "Replacements.originValue": "Исходное значение",
  "Replacements.targetValue": "Замена",

  StopWords: "Стоп-слова",
  "StopWords.Add": "Добавить стоп-слово",
  "StopWords.Form": "Редактировать стоп-слово",
  "StopWords.Info": "Информация",
  "StopWords.value": "Значение",

  FilterWords: "Фильтр-слова",
  "FilterWords.Add": "Добавить фильтр-слово",
  "FilterWords.Form": "Редактировать фильтр-слово",
  "FilterWords.Info": "Информация",
  "FilterWords.value": "Значение",

  ImageStyles: "Стили генерации",
  "ImageStyles.Add": "Добавить стиль",
  "ImageStyles.Form": "Редактировать стиль",
  "ImageStyles.Info": "Информация",
  "ImageStyles.name": "Название",
  "ImageStyles.text": "Инструкции",

  Listeners: "Слушатели",
  "Listeners.sessionId": "Слушатель",
  "Listeners.firstDate": "Первая дата",
  "Listeners.lastDate": "Последняя дата",
  "Listeners.dataCount": "Прослушивания",
  "Listeners.activityDaysCount": "Дни",
  "Listeners.progressListening": "Дослушивания, %",
  "Listeners.progressTime": "Время",

  Channels: "Каналы",
  "Channels.Add": "Добавить канал",
  "Channels.Order": "Сортировать каналы",
  "Channels.Form": "Редактировать данные",
  "Channels.Info": "Информация",
  "Channels.Stats": "Статистика",
  "Channels.Messages": "Сообщения",
  "Channels.Messages.url": "Ссылка на сообщение",
  "Channels.name": "Название",
  "Channels.searchName": "Название для поиска",
  "Channels.castsSchedules": "Частотность выпусков",
  "Channels.imagePrompt": "Промпт для картинок по умолчанию",
  "Channels.soundName": "Ролик",
  "Channels.introSoundMessage": "Вступительное сообщение",
  "Channels.soundFileId": "Файл",
  "Channels.alias": "Ссылка",
  "Channels.description": "Описание",
  "Channels.category": "Категория",
  "Channels.partner": "Админ",
  "Channels.approveMessages": "Принимать все сообщения",
  "Channels.playlistDefault": "Лучшее",
  "Channels.playlistCategory": "В плейлисте",
  "Channels.file": "Лого",
  "Channels.templateFileId": "Шаблон обложки",
  "Channels.rejectCasts": "Отменить выпуски",
  "Channels.Settings": "Настройки",
  "Channels.Settings.excludeStrings": "Исключения",
  "Channels.Settings.stopWords": "Стоп-слова",
  "Channels.Settings.keepHashtags": "Сохранять хештеги",

  Premiums: "Премиумы",
  "Premiums.Add": "Добавить премиум",
  "Premiums.Form": "Редактировать данные",
  "Premiums.Info": "Информация",
  "Premiums.name": "Название",
  "Premiums.duration": "Длительность",
  "Premiums.prices": "Цены",

  Messages: "Сообщения",
  "Messages.Add": "Добавить сообщение",
  "Messages.Form": "Редактировать данные",
  "Messages.Info": "Информация",
  "Messages.ident": "Код",
  "Messages.text": "Текст",
  "Messages.title": "Заголовок",
  "Messages.date": "Дата",
  "Messages.score": "Оценка",
  "Messages.mp3": "mp3",
  "Messages.status": "Статус",
  "Messages.duration": "Длительность",
  "Messages.prices": "Цены",
  "Messages.channel": "Канал",

  // заказы
  Orders: "Заказы",
  "Orders.Order": "Данные заказа",
  "Orders.info": "Информация",
  "Orders.ident": "ID",
  "Orders.costs": "Цена",
  "Orders.customer": "Клиент",
  "Orders.partner": "Партнер",
  "Orders.payout": "Выплата",
  "Orders.treeCost": "Цена",
  "Orders.commission": "Комиссия",

  Sounds: "Звуки",
  "Sounds.Settings": "Настройки",
  "Sounds.Settings.jingleFileId": "Джингл",
  "Sounds.Settings.textToSpeechModel": "Голосовая модель",
  "Sounds.Settings.ttsHD": "HD качество",
  "Sounds.Settings.textSpeed": "Скорость речи, %",
  "Sounds.Settings.textInstructions": "Инструкции к тексту (выразительно, плавно, внятно, и т.д)",
  "Sounds.Settings.test": "Протестировать голос (перед тестом нужно сохранить изменения)",
  "Sounds.Backgrounds": "Фоновые звуки",
  "Sounds.Backgrounds.name": "Название",
  "Sounds.Backgrounds.originFileId": "Исходный файл",
  "Sounds.Backgrounds.volume": "Громкость, %",
  "Sounds.Backgrounds.volumeFileId": "Файл с установленной громкостью",

  "Sounds.Compiler": "Сборщик",
  "Sounds.Compiler.mp3": "mp3",
  "Sounds.Compiler.image": "Картинка",
  "Sounds.Compiler.title": "Заголовок",
  "Sounds.Compiler.author": "Исполнитель",
  "Sounds.Compiler.submit": "Отправить",

  Payments: "Транзакции",
  "Payments.value": "Сумма",
  "Payments.currency": "Валюта",
  "Payments.customer": "Клиент",
  "Payments.status": "Статус",
  "Payments.status.new": "Новая",
  "Payments.status.wait_payment": "Ожидает оплаты",
  "Payments.status.paid": "Оплачена",
  "Payments.status.error": "Ошибка",
  "Payments.status.declined": "Отменена",

  "Payments.Settings": "Настройки",
  "Payments.Settings.textToSpeechCost": "Стоимость обработки",
  "Payments.Settings.textToSpeechCharsAmount": "За количество символов",
  "Payments.Settings.textToSpeechModel": "Модель",
  "Payments.Settings.textSpeed": "Скорость текста, %",
  "Payments.Settings.textToSpeechCurrencyId": "Валюта за обработку",

  "Payments.Transactions": "Платежи",
  "Payments.Receipts": "Чеки",
  "Payments.ReceiptsServices": "Чековые сервисы",
  "Payments.PaymentsServices": "Платежные сервисы",
  "Payments.Currencies": "Валюты",
  "Payments.Currencies.marketplaces": "Используется на площадках",
  "Payments.Currencies.isoName": "ISO4217 название",
  "Payments.Currencies.isoCode": "ISO4217 цифровой код",

  PublishChannels: "Каналы для публикаций",
  "PublishChannels.name": "Название",
  "PublishChannels.alias": "Alias",
  "PublishChannels.accountId": "Аккаунт для публикации",

  Countries: "Страны",
  "Countries.code": "Код",
  "Countries.currency": "Валюта",

  Receipts: "Чеки",
  "Receipts.Receipt": "Чек",
  "Receipts.type": "Тип",
  [`Receipts.type.buy_tree`]: "Покупка дерева",

  "Receipts.customer": "Клиент",
  "Receipts.status": "Статус",
  [`Receipts.status.new`]: "Новый",
  [`Receipts.status.new.style`]: "secondary",
  [`Receipts.status.ready`]: "Готов к обработке",
  [`Receipts.status.ready.style`]: "secondary",
  [`Receipts.status.process`]: "В обработке",
  [`Receipts.status.process.style`]: "secondary",
  [`Receipts.status.success`]: "Завершен",
  [`Receipts.status.success.style`]: "success",
  [`Receipts.status.error`]: "Ошибка",
  [`Receipts.status.error.style`]: "danger",

  Genders: "Пол",
  "Genders.male": "Мужской",
  "Genders.female": "Женский",
  "Genders.null": "Неизвестно",

  Locales: "Локализации",
  "Locales.Locale": "Язык",
  "Locales.icon": "Иконка",
  "Locales.code": "Код",
  "Locales.isDefault": "Язык по умолчанию",
  "Locales.ru": "Русский",
  "Locales.en": "English",

  Marketplaces: "Торговые площадки",
  "Marketplaces.bgColor": "Цвет фона",
  "Marketplaces.fontColor": "Цвет шрифта",
  "Marketplaces.logo": "Логотип",
  "Marketplaces.url": "Ссылка",

  Rewards: "Награды и предметы",
  "Rewards.file": "Файл",
  "Rewards.symbol": "Символ",
  "Rewards.type": "Тип",
  "Rewards.expKoef": "Коэффициент",
  "Rewards.values": "Значения",

  Reactions: "Реакции",
  "Reactions.references": "Доступно для элементов",
  "Reactions.rewardsValues": "Выдаваемые награды",

  DateTimeGroups: "Масштаб времени",
  "DateTimeGroups.now": "Сейчас",
  "DateTimeGroups.toStartOfHour": "По часам",
  "DateTimeGroups.toStartOfDay": "По дням",
  "DateTimeGroups.toStartOfWeek": "По неделям",
  "DateTimeGroups.toStartOfMonth": "По месяцам",
  "DateTimeGroups.toStartOfYear": "По годам",

  PaymentsServicesTypes: "Платежный сервис",
  "PaymentsServicesTypes.yoo_kassa": "Ю-Касса",
  "PaymentsServicesTypes.tinkoff_kassa": "Тинькофф-Касса",
  "PaymentsServicesTypes.stripe": "Stripe",

  ReceiptsServicesTypes: "Кассовый сервис",
  "ReceiptsServicesTypes.cloud_kassir": "Cloud Kassir",
  "ReceiptsServicesTypes.kit_online": "KIT online",

  AstroSigns: "Знаки зодиака",
  "AstroSigns.aries": "Овен",
  "AstroSigns.taurus": "Телец",
  "AstroSigns.gemini": "Близнецы",
  "AstroSigns.cancer": "Рак",
  "AstroSigns.leo": "Лев",
  "AstroSigns.virgo": "Дева",
  "AstroSigns.libra": "Весы",
  "AstroSigns.scorpio": "Скорпион",
  "AstroSigns.sagittarius": "Стрелец",
  "AstroSigns.capricorn": "Козерог",
  "AstroSigns.aquarius": "Водолей",
  "AstroSigns.pisces": "Рыбы",
  "AstroSigns.null": "Неизвестно",

  Contents: "Справочники",
  "Contents.Add": "Добавить",
  "Contents.Form": "Редактировать",
  "Contents.name": "Название",
  "Contents.value": "Значение",
  "Contents.text": "Текст",
  "Contents.file": "Файл",
  "Contents.tag": "Тег",
  "Contents.icon": "Иконка",
  "Contents.isPaid": "Платный доступ",
  "Contents.category": "Категория",
  "Contents.itemCategory": "Мемстер",
  "Contents.topic": "Тема",

  Adverts: "Реклама",
  "Adverts.Add": "Добавить рекламу",
  "Adverts.name": "Название",
  "Adverts.content": "Ролик",
  "Adverts.url": "Ссылка",
  "Adverts.file": "Файл",
  "Adverts.legalName": "Юр лицо",
  "Adverts.image": "Лого",
  "Adverts.title": "Заголовок",
  "Adverts.Intros": "Вступления",
  "Adverts.Settings": "Настройки",
  "Adverts.Settings.limit": "Количество прослушанных сообщений",
  "Adverts.Settings.offset": "Отступ для рандомного окна (+/-)",
  "Adverts.Settings.maxSeconds": "Количество секунд прослушивания",
  "Adverts.Settings.daysInterval": "Количество дней прослушивания",
  "Adverts.Settings.maxRepeats": "Число повторений рекламы на пользователя",

  Advertisers: "Рекламодатели",
  "Advertisers.Intros": "Вступления",
  "Advertisers.Add": "Добавить рекламодателя",
  "Advertisers.Data": "Данные",
  "Advertisers.Info": "Информация",
  "Advertisers.Adverts": "Ролики",
  "Advertisers.Stats": "Статистика",
  "Advertisers.Companies": "Кампании",
  "Advertisers.Users": "Аккаунты",
  "Advertisers.name": "Название",

  "Companies.Add": "Добавить кампанию",
  "Companies.advert": "Объявление",
  "Companies.name": "Название",
  "Companies.text": "Текст",
  "Companies.mp3": "Ролик",
  "Companies.category": "Категория",
  "Companies.startDate": "Дата запуска",
  "Companies.endDate": "Дата завершения",
  "Companies.totalLimit": "Лимит",
  "Companies.dayLimit": "День",
  "Companies.prices": "Цена за показ",
  "Companies.remainsLimit": "Остаток",
  "Companies.income": "Доход",
  "Companies.statsCount": "Прослушивания",
  "Companies.coverage": "Охват",
  "Companies.totalCost": "Бюджет",
  "Companies.status": "Статус",
  "Companies.status.new": "Черновик",
  "Companies.status.in_progress": "Запущена",
  "Companies.status.paused": "Пауза",
  "Companies.status.completed": "Завершена",

  Items: "Товары",
  "Items.List": "Регистрации",
  "Items.Add": "Добавить товар",

  "Items.Types": "Типы продукции",
  "Items.Types.Add": "Добавить тип",
  "Items.Types.name": "Название",
  "Items.Types.isDigital": "Цифровой",

  "Items.Seasons": "Сезоны продукции",
  "Items.Seasons.Add": "Добавить сезон",
  "Items.Seasons.name": "Название",
  "Items.Seasons.isCurrent": "Текущий",

  Categories: "Категории",
  "Categories.Add": "Добавить категорию",
  "Categories.name": "Название",
  "Categories.alias": "Ссылка",
  "Categories.description": "Описание",
  "Categories.castsSchedules": "Частность выпусков",
  "Categories.prices": "Цены за показ по умолчанию",
  "Categories.imagePrompt": "Промпт для картинки по умолчанию",
  "Categories.castTitle": "Заголовок для выпусков",
  "Categories.soundText": "Озвучка для выпусков",
  "Categories.soundFileId": "Файл озвучки для выпусков",

  "Items.category": "Категория",
  "Items.emit": "Партия",
  "Items.ident": "Код",
  "Items.activateDate": "Дата активации",
  "Items.Emits": "Партии",
  "Items.Emits.Add": "Новая партия",
  "Items.Emits.quantity": "Количество",
  "Items.Emits.comment": "Комментарий",

  Moderation: "Модерация",
  [`Moderation.stats.claims`]: "Жалобы",
  [`Moderation.stats.claims.style`]: "danger",
  [`Moderation.stats.publications`]: "Публикации",
  [`Moderation.stats.publications.style`]: "success",

  Claims: "Жалобы",
  [`Claims.stats.new`]: "Новые",
  [`Claims.stats.new.style`]: "danger",

  "Claims.Details": "Детали",
  "Claims.message": "Жалоба",
  "Claims.user": "Пользователь",
  "Claims.reference": "Сущность",
  "Claims.quantity": "Количество",
  "Claims.status": "Статус",
  "Claims.setClaimed": "Утвердить жалобу",
  "Claims.rejectClaims": "Отклонить жалобы",

  Publications: "Публикации",
  [`Publications.stats.new`]: "Новые",
  [`Publications.stats.new.style`]: "success",

  "Publications.Comments": "Комментарии",
  "Publications.Form": "Редактировать",
  "Publications.attachments": "Приложения",
  "Publications.attachments.references": "Тип",
  "Publications.content": "Сообщение",
  "Publications.localedContent": "Локализованное сообщение",
  "Publications.locale": "Оригинальная локализация",
  "Publications.user": "Пользователь",
  "Publications.publishedAt": "Дата публикации",

  //
  Specials: "Спецвыпуски",
  "Specials.Add": "Новый спецвыпуск",
  "Specials.Form": "Редактировать",
  "Specials.Categories": "Категории спецвыпусков",

  "Specials.category": "Категория",
  "Specials.name": "Название",
  "Specials.text": "Трейлер",
  "Specials.soundNameFileId": "Озвучка названия",
  "Specials.soundTextFileId": "Озвучка трейлера",

  "Specials.translationPublish": "Добавлять в трансляцию",
  "Specials.telegramPublish": "Публиковать в телеграм",
  "Specials.telegramTextPublish": "Формат публикации",
  "Specials.telegramTextPublish.text": "Текст",
  "Specials.telegramTextPublish.audio": "Аудио",
  "Specials.templateFileId": "Шаблон для выпуска",
  "Specials.telegramStoryPublish": "Делать сториз",
  "Specials.storyTemplateFileId": "Шаблон для сториз",

  "Specials.fileId": "Обложка",
  "Specials.telegramMessageId": "Публикация в телеграм",
  "Specials.status": "Статус",
  "Specials.status.draft": "Черновик",
  "Specials.status.draft.style": "secondary",
  "Specials.status.in_progress": "Публикуется",
  "Specials.status.in_progress.style": "warning",
  "Specials.status.published": "Опубликован",
  "Specials.status.published.style": "success",
  "Specials.status.deleted": "Удален",
  "Specials.status.deleted.style": "danger",

  "Specials.Messages": "Сообщения",
  "Specials.Messages.Add": "Добавить сообщение",
  "Specials.Messages.title": "Заголовок",
  "Specials.Messages.text": "Текст",
  "Specials.Messages.fileId": "Озвучка",
  "Specials.Messages.channel": "Канал",
  "Specials.Messages.ident": "Код сообщения",

  //
  Www: "Cайт",
  "Www.Settings": "Настройки",
  "Www.Settings.astroWeekCost": "Стоимость покупки прогноза на неделю",
  "Www.Settings.phone": "Номер телефона",
  "Www.Settings.email": "Email",
  "Www.Settings.address": "Адрес",
  "Www.Settings.intro_video": "Вступительное видео (код для вставки)",
  "Www.Settings.whatsapp": "Ссылка в WhatsApp",
  "Www.Settings.instagram": "Ссылка на инстаграм",
  "Www.Settings.facebook": "Ссылка на Фейсбук",
  "Www.Settings.vk": "Ссылка на Вконтакте",
  "Www.Settings.telegram": "Ссылка на Telegram",
  "Www.Settings.telegramChannel": "Ссылка на телеграм канал",
  "Www.Settings.telegramBot": "Ссылка на бота телеграм",
  "Www.Settings.telegramBotStartMessage": "Приветственное сообщение бота",
  "Www.Settings.telegramWebApp": "Ссылка на телеграм WebApp",
  "Www.Settings.telegramAccountId": "Аккаунт публикаций в телеграм канал",
  "Www.Settings.telegramStoriesAccountId": "Аккаунт для публикаций сториз",
  "Www.Settings.description": "Описание сервиса",
  "Www.Settings.footerText": "Текст в подвале",
  "Www.Settings.introVideo": "Вступительное видео",
  "Www.Settings.logoFileId": "Логотип",
  "Www.Settings.translationFileId": "Картинка для трансляций",
  "Www.Settings.telegramBottomText": "Подпись выпуска в телеграм",
  "Www.Settings.allowSelectCountry": "Выбор страны",
  "Www.Settings.versionNumber": "Версия",
  "Www.Settings.playlistText": "Пустой плейлист",
  "Www.Settings.playlistVariants": "Подсказки для плейлиста",
  "Www.Settings.channelsDescription": "Превью каналов",
  "Www.Settings.favoritesDescription": "Описание для избранного",
  "Www.Settings.specialsDescription": "Описание для спецвыпусков",
  "Www.Settings.defaultImageId": "Картинка по умолчанию",
  "Www.Settings.finalSound": "Сообщение при завершении",
  "Www.Settings.playerSpeed": "Скорость воспроизведения плеера",
  // "Www.Settings.adContacts": "Контакты рекламодателя",
  "Www.Settings.aboutContacts": "Контакты сотрудничества",
  "Www.Settings.specialsBlock": "Блок `Спецпроекты`",
  "Www.Settings.subscribeBlock": "Блок `Подписаться`",
  "Www.Settings.liveStreamPage": "Страница `Трансляция`",
  "Www.Settings.liveStreamBlock": "Блок `Трансляция`",
  "Www.Settings.channelInfoBlock": "Блок `Канал в телеграм`",
  "Www.Settings.howItWorkBlock": "Блок `Как это работает`",
  "Www.Settings.joinChannelBlock": "Блок `Подключить канал`",
  "Www.Settings.soundtrackFileId": "Фоновая музыка",
  "Www.Settings.backgroundSounds": "Фоновая музыка",
  "Www.Settings.jingleFileId": "Звук джингла",
  "Www.Settings.helpText": "Текст помощи пользователю",
  "Www.Settings.stopText": "Текст ошибки регистрации игрушки",
  "Www.Settings.shareScreenText": "Текст на экране `поделиться`",
  "Www.Settings.softToys": "Описания мягких игрушек",
  "Www.Settings.digitalToys": "Описания цифровых игрушек",

  "Www.Landing": "Лендинг",
  "Www.Landing.soundMessage": "Звуковое сообщение",
  "Www.Landing.intro": "Титульный блок",
  "Www.Landing.extra": "Дополнительный блок",
  "Www.Landing.block1": "Блок 1",
  "Www.Landing.block2": "Блок 2",
  "Www.Landing.block3": "Блок 3",
  "Www.Landing.block4": "Блок 4",
  "Www.Landing.block5": "Блок 5",

  "Www.Landing.title": "Название",
  "Www.Landing.content": "Содержание",
  "Www.Landing.isHtml": "HTML-контент",
  "Www.Landing.image": "Изображение",

  "Www.Meta": "Мета-данные",
  "Www.Meta.sitename": "Название сайта",
  "Www.Meta.index": "Главная страница",
  "Www.Meta.account": "Аккаунт",
  "Www.Meta.ad": "Рекламодателям",
  "Www.Meta.channels": "Каналы",
  "Www.Meta.join": "Каналам",
  "Www.Meta.playlist": "Плейлист",
  "Www.Meta.about": "О проекте",
  "Www.Meta.live": "Трансляция",
  "Www.Meta.specials": "Спецвыпуски",

  "Www.Meta.title": "Заголовок",
  "Www.Meta.description": "Описание",
  "Www.Meta.keywords": "Ключевые слова",
  "Www.Meta.image": "Изображение",

  "Www.Faq": "FAQ",
  "Www.Faq.Add": "Добавить вопрос",
  "Www.Faq.Form": "Редактировать вопрос",
  "Www.Faq.title": "Название",
  "Www.Faq.ident": "ident",
  "Www.Faq.type": "Тип",
  "Www.Faq.question": "Вопрос",
  "Www.Faq.answer": "Ответ",

  "Www.Contacts": "Контакты",
  "Www.Contacts.Add": "Добавить новый",
  "Www.Contacts.Form": "Редактировать",
  "Www.Contacts.title": "Заголовок",
  "Www.Contacts.url": "Ссылка",
  "Www.Contacts.image": "Изображение",

  "Www.Contents": "Контент",
  "Www.Contents.Add": "Добавить новый",
  "Www.Contents.Form": "Редактировать контент",
  "Www.Contents.title": "Заголовок",
  "Www.Contents.data": "Содержимое",
  "Www.Contents.image": "Изображение",
  "Www.Contents.url": "Ссылка",

  "Www.Feedbacks": "Обратная связь",
  "Www.Feedbacks.Add": "Добавить запись",
  "Www.Feedbacks.Form": "Редактировать запись",
  "Www.Feedbacks.realDate": "Реальная дата отзыва",
  "Www.Feedbacks.indexPage": "Показывать на главной",
  "Www.Feedbacks.localesName": "Автор",
  "Www.Feedbacks.localesText": "Отзыв",
  "Www.Feedbacks.images": "Изображения",
  "Www.Feedbacks.value": "Оценка",

  "soundMessage.button": "Кнопка",
  "soundMessage.title": "Заголовок",
  "soundMessage.content": "Текст",
  "soundMessage.mp3": "Mp3",
  "soundMessage.image": "Картинка",

  "contactsData.title": "Заголовок",
  "contactsData.content": "Текст",
  "contactsData.telegram": "Телеграм",
  "contactsData.email": "Email",
  "contactsData.image": "Картинка",

  "subscribeData.title": "Заголовок",
  "subscribeData.content": "Текст",
  "subscribeData.button": "Кнопка",
  "subscribeData.image": "Картинка",

  meta: "Мета аттрибуты",
  "meta.title": "Заголовок",
  "meta.description": "Описание",
  "meta.keywords": "Ключевые слова",
  "meta.image": "Изображение",
  error: "Ошибка",
  "error.auth-error": "Ошибка авторизации",
  confirmAction: "Подтвердите действие",
  ok: "Подтвердить",
  save: "Cохранить",
  saving: "Данные сохраняются",
  saved: "Данные сохранены",
  add: "Добавить",
  edit: "Редактировать",
  delete: "Удалить",
  remove: "Удалить",
  cancel: "Отмена",
  total: "Всего",
  logout: "Выйти",
  "not-found": "не найдено",
  loading: "Идет загрузка",
  noData: "Нет данных",
  "tables.title": "Название",
  // "tables.enabled": "Заблокировано",
  "tables.blocked_filter": "Заблокированные",
  "tables.enabled": "Активность",
  "tables.enabled.yes": "Активен",
  "tables.enabled.no": "Неактивен",
  "tables.status": "Статус",
  "tables.created": "Дата",
  "tables.updated": "Обновлено",
  dateFormat: "DD.MM.YYYY",
  timeFormat: "HH:mm:ss",
  dateTimeFormat: "DD.MM.YYYY HH:mm",
  "pager.limit": "Кол-во на странице",
  free: "Бесплатно",
  url: "Ссылка",
  hoursFrom: "С",
  hoursTill: "По",
  volume: "Громкость (1~100)",

  hours: "{value} ч.",
  minutes: "{value} мин.",
  seconds: "{value} сек.",

  //
  symbol: "Символ",
  name: "Название",
  localesName: "Локализация названия",
  localesDescription: "Локализация описания",
  value: "Значение",
  oldValue: "Старое значение",
  type: "Тип",
  description: "Описание",

  text: "Текст",
  file: "Файл",

  date: "Дата",
  minDate: "Начало периода",
  maxDate: "Конец периода",

  // типовые значения
  ok: "OK",
  confirm: "Подтвердить",
  yes: "Да",
  no: "Нет",
  close: "Закрыть",
  select_value: "Выберите значение",
  urlCopied: "Ссылка скопирована",
  download: "Скачать",
  time: "Время",
  number: "Номер",

  blocked_filter: "Показать/скрыть заблокированные",

  Special: "Служебные",
  "Special.Translates": "Переводы",
  "Special.Translates.Add": "Новая задача",
  "Special.Translates.referenceName": "Модель данных",
  "Special.Translates.sourceLocale": "Исходная локализация",
  "Special.Translates.targetLocale": "Целевая локализация",
  "Special.Translates.skipTranslated": "Пропускать уже переведенные",
  "Special.Translates.status": "Статус",
  "Special.Translates.symbolsCount": "Кол-во символов",
  "Special.Translates.detectedId": "Всего записей",
  "Special.Translates.completedId": "Завершено",
  "Special.Translates.skippedId": "Пропущено",
  "Special.Translates.errorId": "Ошибки",

  Auth: "Авторизация",
  "Auth.login": "Логин",
  "Auth.password": "Пароль",
  "Auth.doLogin": "Вход",
  "Auth.forgotPassword": "Забыли пароль?",
  "Auth.enterRestoreData": "Введите номер телефона, на который зарегистрирована учетная запись",
  "Auth.confirmCode": "Введите проверочный код",
  "Auth.doRequestCode": "Запросить проверочный код",
  "Auth.doConfirmCode": "Подтвердить код",
  "Auth.wrong-login": "Неверный логин",
  "Auth.wrong-code": "Неверный код",
  "Auth.wrong-user": "Недоступный пользователь",
  "Auth.wrong-password": "Неверный пароль",
  "Auth.wrong-data": "Неверные данные",
  "Auth.login-required": "Требуется ввести логин",
  "Auth.password-required": "Требуется ввести пароль",
  "Auth.back": "На страницу авторизации",
  "Auth.in-progress": "Авторизация в системе",

  "sidebar.demo": "Демо",
  "sidebar.demopage": "Демо страница",
  "sidebar.dashboard": "Сводка",
  "sidebar.pages": "Страницы",
  "sidebar.authentication": "Authentication",
  "sidebar.login": "Login",
  "sidebar.register": "Register",
  "sidebar.forgotpassword": "Forgot Password",
  "sidebar.userpages": "User Pages",
  "sidebar.lockscreen": "Lock Screen",
  "sidebar.timeline": "Timeline",
  "sidebar.userprofile": "User Profile",
  "sidebar.error": "Error",
  "sidebar.400": "400",
  "sidebar.forms": "Forms",
  "sidebar.regularforms": "Regular Forms",
  "sidebar.extendedforms": "Extended Forms",
  "sidebar.validationforms": "Validation Forms",
  "sidebar.wizard": "Wizard",
  "sidebar.chat": "Chat",
  "sidebar.select": "Select",
  "sidebar.selectcontrols": "Select Controls",
  "sidebar.googlemaps": "Google Maps",
  "sidebar.fullscreenmap": "Full Screen Map",
  "sidebar.vectormap": "Vector Map",
  "sidebar.tables": "Tables",
  "sidebar.regulartabels": "Regular Tables",
  "sidebar.extendedtables": "Extended Tables",
  "sidebar.reacttables": "React Table",
  "sidebar.components": "Components",
  "sidebar.buttons": "Buttons",
  "sidebar.custombuttons": "Custom Buttons",
  "sidebar.gridsystem": "Grid System",
  "sidebar.panels": "Panels",
  "sidebar.sweetalert": "Sweet Alert",
  "sidebar.notifications": "Notifications",
  "sidebar.icons": "Icons",
  "sidebar.typography": "Typography",
  "sidebar.widgets": "Widgets",
  "sidebar.charts": "Charts",
  "sidebar.calender": "Calender",
  "sidebar.gauges": "Gauges",
  "sidebar.datepicker": "DatePicker",
  "sidebar.reactstrapcomponents": "React Strap",
  "sidebar.alerts": "Alerts",
  "sidebar.badges": "Badges",
  "sidebar.breadcrumbs": "Breadcrumbs",
  "sidebar.buttondropdown": "Button Dropdown",
  "sidebar.buttongroup": "Button Group",
  "sidebar.card": "Card",
  "sidebar.carousel": "Carousel",
  "sidebar.collapse": "Collapse",
  "sidebar.dropdowns": "Dropdowns",
  "sidebar.fade": "Fade",
  "sidebar.form": "Form",
  "sidebar.maps": "Maps",
  "sidebar.tracking-vehicle": "Tracking Vehicle",
  "sidebar.inputgroup": "Input Group",
  "sidebar.jumbotron": "Jumbotron",
  "sidebar.layout": "Layout",
  "sidebar.listgroup": "ListGroup",
  "sidebar.media": "Media",
  "sidebar.modals": "Modals",
  "sidebar.navbar": "Navbar",
  "sidebar.nav": "Nav",
  "sidebar.pagination": "Pagination",
  "sidebar.popovers": "Popovers",
  "sidebar.progress": "Progress",
  "sidebar.tabs": "Tabs",
  "sidebar.tooltips": "Tooltips",
  "sidebar.app": "Приложение",
  "sidebar.userinterface": "User Interface",
  "sidebar.style&userinterface": "Style & User Interface",
  "sidebar.uicomponents": "Ui Components",
  "sidebar.others": "Others",
  "sidebar.menuexamples": "Examples",
  "sidebar.500": "500",
  "sidebar.single": "Single",
  "sidebar.onelevel": "One Level",
  "sidebar.secondlevel": "Second Level",
  "sidebar.example": "Example",
  "sidebar.example1": "Example 1",
  "sidebar.example2": "Example 2",
  "sidebar.colors": "Colors",
  "sidebar.pricing": "Pricing",
  "sidebar.style1": "Style 1",
  "sidebar.style2": "Style 2",
  "sidebar.contact": "Contact",
  "sidebar.themes": "Themes",
  "sidebar.basicelement1": "Basic Element 1",
  "sidebar.basicelement2": "Basic Element 2",
  "sidebar.advanceelement": "Advance Element",
  "sidebar.pricingpages": "Pricing Pages",
  "sidebar.errorpages": "Error Pages",
  "sidebar.scrumboard": "Scrumboard",
  "sidebar.editors": "Editors",
  "sidebar.roeeditor": "Roe Editor",
  "sidebar.roemarkupeditor": "Roe Markup Editor",
  "sidebar.wysiwygeditor": "Wysiwyg Editor",
  "sidebar.quill": "Quill",
  "sidebar.quilleditor": "Quill Editor",
  "sidebar.foreditor": "For Editor",
  "sidebar.socialwidgets": "Social Widgets",
  "sidebar.chartwidgets": "Chart Widgets",
  "sidebar.blogwidgets": "Blog Widgets",
  "sidebar.statisticswidgets": "Statistics Widgets",
  "sidebar.filemanager": "File Manager",
  "sidebar.todos": "Todos",
  "themeChanger.settings": "Settings",
  "themeChanger.sidebar": "Sidebar Varients",
  "themeChanger.footer": "Footer",
  "themeChanger.background": "Background",
  "themeChanger.topbar": "Topbar",
  "themeSetting.layouts": "Layouts",
  "themeSetting.vertical": "Vertical",
  "themeSetting.horizontal": "Horizontal",
  "themeSetting.themebuilder": "Theme Builder",
  "themeSetting.alignments": "Alignments",
  "themeSetting.topbar": "Topbar",
  "themeSetting.footer": "Footer",
  "themeSetting.display": "Display",
  "themeSetting.below": "Below",
  "themeSetting.above": "Above",
  "themeSetting.show": "Show",
  "themeSetting.hide": "Hide",
  "themeSetting.on": "On",
  "themeSetting.off": "Off",
  "themeSetting.tranperentmod": "Transperent Mode",
  "themeSetting.sidebartransparent": "Sidebar Transparent",
  "themeSetting.activepanel": "Sidebar Variant",
  "themeSetting.style1": "style1",
  "themeSetting.style2": "style2",
  "themeSetting.style3": "style3",
  "themeSetting.style4": "style4",
  "themeSetting.colorSchema": "Sidebar Scheme",
  "themeSetting.sidebarmini": "Sidebar Mini",
  "themeSetting.gradients": "Gradients",
  "languageSwitcher.label": "Language",
  "header.editprofile": "Edit Profile",
  "header.settings": "Settings",
  "header.inbox": "Inbox",
  "header.signout": "Signout",
};
