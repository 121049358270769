export const apiUrls = {
  list: "/api/channels",
  fastAdd: "/api/channels/fast",
  setOrder: "/api/channels/setOrder",
  info: "/api/channels/channel_{channelId}",
  settings: "/api/channels/channel_{channelId}/settings",
  checkChannel: "/api/channels/check",
  messages: "/api/channels/channel_{channelId}/messages",
  getImage: "/api/channels/channel_{channelId}/image",
  delete: "/api/channels/channel_{channelId}/delete",
  rejectCasts: "/api/channels/channel_{channelId}/rejectCasts",
  fastAddMessages: "/api/channels/channel_{channelId}/messages/fastAdd",
  messageInfo: "/api/channels/channel_{channelId}/messages/message_{messageId}",
};

export const path = "/channels";

export const infoLink = (channel) => {
  return `${path}/channel_${channel._id}`;
};

export const formLink = (channel) => {
  return `${infoLink(channel)}/form`;
};
