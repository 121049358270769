/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import moment from "moment";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Finder, Pager, TableLoading, AudioPreview } from "components/standart";
import { FloatChannels } from "components/special/contents";
import { useIntl } from "react-intl";

import { limit } from "variables";

import { apiUrls } from "../init";
import AddMessage from "./Add";
import ChannelMessageForm from "./Form";
const { api } = adminAPI.actions;

const ChannelsMessagesTable = (props) => {
  const params = useParams();
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [messages, setMessages] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ ...params, limit, ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setMessages(body);
    };
    const onRequest = () => {
      setMessages({ total: -1 });
    };

    query.limit && props.getChannelMessages({ params: query, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th className="text-nowrap">
            <Finder className="mr-1 d-inline" isActive={query.ident}>
              <Finder.input name="ident" {...{ query, setQuery }} />
            </Finder>
            <IntlMessages id="Messages.ident" />
          </th>
          <th>
            <IntlMessages id="Messages.mp3" />
          </th>
          <th>
            <IntlMessages id="Messages.text" />
          </th>
          <th>
            <IntlMessages id="Messages.status" />
          </th>
          <th>
            <IntlMessages id="Messages.score" />
          </th>
          {/* <th>
            <IntlMessages id="Messages.date" />
          </th> */}
          <th className="text-right text-nowrap col-4">
            <Finder.search {...{ query, url }} className="mr-1" />

            <AddMessage refreshHandler={refreshHandler} />
          </th>
        </tr>
      </thead>
      {messages.total < 0 && <TableLoading />}
      <tbody>
        {_.map(messages.data, (message, key) => {
          return (
            <tr key={key}>
              <td>{message.ident}</td>
              <td>{message.fileId ? <AudioPreview file={{ _id: message.fileId }} /> : null}</td>
              <td>
                <div className="text-truncate" style={{ width: "200px" }}>
                  {message.text}
                </div>
              </td>
              <td>{message.status}</td>
              <td>{message.score}</td>
              {/* <td></td> */}

              <td className="text-right">
                <div className="d-flex pull-right">
                  <div className="btn">
                    {message.date ? moment(message.date).format(dateTimeFormat) : null}
                  </div>
                  <div>
                    <ChannelMessageForm {...{ message, refreshHandler }} />
                  </div>
                </div>
                {/* <FormButton {...{ userForm, user: login }} className="btn btn-sm btn-secondary" /> */}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={messages.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default connect(null, {
  getChannelMessages: api.get(apiUrls.messages),
})(ChannelsMessagesTable);
