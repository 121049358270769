import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import qs from "query-string";
import IntlMessages from "util/intlMessages";
import ChannelMessageData from "./Data";
import { adminAPI } from "redux/api";
import { apiUrls } from "../init";
import { LoadingIcon } from "components/special";

const { api } = adminAPI.actions;

const AddMessage = (props) => {
  const params = useParams();
  const location = useLocation();

  const [urls, setUrls] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  // const changeUser = setOnChange(newUser, setNewUser);

  const saveHandler = (req) => {
    const res = {
      onSuccess(body) {
        props.refreshHandler();
        toggleModal();
        setLoading(false);
      },
      onRequest() {
        setLoading(true);
        setError(null);
      },
      onFailure({ message }) {
        setError(message);
        setLoading(false);

      },
    };
    const body = { urls };
    props.addMessage({ body, params }, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const addUrls = (event) => {
    setUrls([...urls, ""]);
  };

  const changeUrls = (index) => (event) => {
    const { value } = event.target;
    setUrls(_.map(urls, (e, _index) => (_index !== index ? e : value)));
  };

  const deleteUrls = (index) => (event) => {
    setUrls(_.filter(urls, (e, _index) => _index !== index));
  };

  useEffect(() => {
    setUrls([]);
    setError(null);
  }, [openModal]);

  return (
    <>
      <button className="btn btn-sm btn-secondary" onClick={toggleModal}>
        <i className="fa fa-plus" />
      </button>

      {openModal && (
        <Modal isOpen={openModal} centered size="lg" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Messages.Add"} />
          </ModalHeader>
          <ModalBody>
            {error && (
              <Alert toggle={() => setError(null)} className="alert-danger mb-3">
                {error}
              </Alert>
            )}

            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="Channels.Messages.url" />
                <button className="btn btn-sm btn-success pull-right" onClick={addUrls}>
                  <i className="fa fa-plus" />
                </button>
              </label>
              <div className="col-8">
                {_.map(urls, (value, index) => {
                  return (
                    <div className="row mt-1 mb-1">
                      <div className="col-10">
                        <input
                          type="text"
                          value={value || ""}
                          onChange={changeUrls(index)}
                          className="form-control react-form-input"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col">
                        <button
                          className="btn btn-sm btn-small btn-danger"
                          onClick={deleteUrls(index)}
                        >
                          <i className="fa fa-times" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <input
              type="text"
              className="form-control"
              placeholder="url"
              value={url || ""}
              onChange={({ target }) => {
                setUrl(target.value);
              }}
            />
            */}
            <div className="my-2" />
            <button
              className="btn btn-success"
              disabled={!_.size(_.filter(urls, _.size)) || loading}
              onClick={saveHandler}
            >
              {loading && <LoadingIcon className="mr-2" />}
              <IntlMessages id={"add"} />
            </button>
            {/* <ChannelMessageData {...{ message, setMessage, saveHandler }} /> */}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addMessage: api.post(apiUrls.fastAddMessages),
})(AddMessage);
